<template>
  <div>
    <div class="row mb-2 align-items-center">
      <div class="align-items-center col-12">
        <i class="fa fa-arrow-left fa-lg text-dark mr-2" @click="$router.go(-1)"></i>
        <span class="font-weight-bold h3">{{ $t('miracle_deal.approved_deals') }}</span>
      </div>
    </div>
    <div v-if="isLoading">
      <div class="text-center">{{ $t('commons.data_loading') }}...</div>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-striped table-bordered collapsed" style="width: 100%" ref="kt_datatable">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('commons.currency_code') }}</th>
            <th scope="col">{{ $t('commons.quantity') }}</th>
            <!-- <th scope="col">{{ $t('miracle_deal.sk_entry_price') }}</th> -->
            <!-- <th scope="col">{{ $t('miracle_deal.fee') }}</th> -->
            <!-- <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th> -->
            <!-- <th scope="col">{{ $t('miracle_deal.striker_to_keeper') }}</th> -->
            <th scope="col">{{ $t('commons.duration') }}</th>
            <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in skmatchList" :key="'skmatch-' + index">
            <th class="font-weight-bolder">{{ item.id }}</th>
            <td>
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40 flex-shrink-0">
                    <div class="media align-items-center">
                      <img :src="`${iconUrl}${item.currency_code.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block" />
                    </div>
                  </div>
                  <div class="ml-3">
                    <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currency_code }}</div>
                  </div>
                </div>
              </span>
            </td>
            <td>{{ item.quantity }}</td>
            <!-- <td>{{ item.price }}</td> -->
            <!-- <td>
              <div class="row justify-content-center">
                <span class="mr-sm-1">%</span>
                <span>{{ item.fee }}</span>
              </div>
            </td> -->
            <!-- <td>
              <div class="row justify-content-center">
                <span class="mr-sm-1">%</span>
                <span>{{ item.ratio }}</span>
              </div>
            </td> -->
            <!-- <td>
              <div class="row justify-content-center">
                <span>{{ item.striker }}</span>
                <span class="mx-sm-1"><i class="fa fa-arrow-right"></i></span>
                <span>{{ item.keeper }}</span>
              </div>
            </td> -->
            <td class="text-muted">
              <div class="row justify-content-center">
                <span class="mr-sm-1">{{ item.duration }}</span>
                <span>{{ textDuration(item.duration_type) }}</span>
              </div>
            </td>
            <td>
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="ml-2">
                    <div v-if="item.end_date" class="text-primary font-weight-bold line-height-sm">{{ item.end_date | dateFormat }}</div>
                  </div>
                </div>
              </span>
            </td>
            <td>
              <div class="flex-column d-flex">
                <button type="button" @click="showDetailsModal(item)" class="btn btn-icon btn-gradient-primary" :title="$t('commons.details')">
                  <i class="la la-info text-white"></i>
                  <span class="text-uppercase">
                    {{ $t('commons.details') }}
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <DealModal v-if="itemDetailsModal" ref="skMatchItemDetailsModal" :title="`#${selectedModalItem.id} - ${$t('commons.details')}`">
      <template v-slot:body>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.sk_entry_price') }}</p>
          <h6 class="mb-0">{{ selectedModalItem.price }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.sk_last_amount') }}</p>
          <h6 class="mb-0">{{ selectedModalItem.last_amount }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.fee') }}</p>
          <h6 class="mb-0">% {{ selectedModalItem.fee }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.customer_ratio') }}</p>
          <h6 class="mb-0">% {{ selectedModalItem.ratio }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.profit', {s_or_k: 'Striker'}) }}</p>
          <h6 class="mb-0">{{ selectedModalItem.striker_transfer }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.profit', {s_or_k: 'Keeper'}) }}</p>
          <h6 class="mb-0">{{ selectedModalItem.keeper_transfer }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.striker_to_keeper') }}</p>
          <h6 class="mb-0">
            <span>{{ selectedModalItem.striker }}</span>
            <span class="mx-sm-1"><i class="fa fa-arrow-right"></i></span>
            <span>{{ selectedModalItem.keeper }}</span>
          </h6>
        </div>
      </template>
    </DealModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dateFormat from '@/config/_date-format';
import DealModal from './DealModal.vue';

const cdnBaseUri = process.env.VUE_APP_OFINANS_CDN;
export default {
  name: 'SKMatchList',
  data() {
    return {
      iconUrl: `${cdnBaseUri}/images/currency/`,
      selectedModalItem: {}
    };
  },
  components: {
    DealModal
  },
  created() {
    const self = this;
    self.$store.dispatch('skmatch/GET_SK_MATCH_LIST');
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.skmatch.isLoading,
      skmatchList: (state) => state.skmatch.skmatchList,
      itemDetailsModal: (state) => state.global.dealModal
    })
  },
  filters: {
    dateFormat
  },
  methods: {
    ...mapActions('skmatch', ['TRANSFER']),
    textDuration(id) {
      switch (id) {
        case 1:
          return 'D';
        case 2:
          return 'M';
        case 3:
          return 'Y';
        case 4:
        case 5:
        default:
          return 'D';
      }
    },
    showDetailsModal(item) {
      this.selectedModalItem = item;
      this.$store.commit('global/SET_DEAL_MODAL', true);
    }
  }
};
</script>
<style scoped>
table,
thead,
tr,
tbody,
th,
td {
  text-align: center;
}

.table th,
td {
  text-align: center;
  vertical-align: middle;
}
</style>
